import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend, { type HttpBackendOptions } from "i18next-http-backend";
import en from "public/locales/en/common.json";

import { CLIENT_CONFIG } from "./config";

export const I18N_DEFAULT_NS = "common";
export const I18N_RESOURCES = {
  en: { common: en },
};

export const I18N_DEFAULT_CONFIG = {
  supportedLngs: ["en", "fr"],
  fallbackLng: "en",
};

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    supportedLngs: I18N_DEFAULT_CONFIG.supportedLngs,
    fallbackLng: I18N_DEFAULT_CONFIG.fallbackLng,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    ns: ["common"],
    defaultNS: I18N_DEFAULT_NS,
    backend: {
      queryStringParams: {
        v: CLIENT_CONFIG.versionI18n,
      },
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
